import Vue from 'vue';

const DEFAULT_SLOP = 3;
const DEFAULT_SIZE = 10;
const FILTERABLE_KEYS = {
    contentType: 'content_type',
};

export default (context) => {
    const { OPENSEARCH_API_URL } = context.$config;
    const api = {
        getPhraseConfig(queryObject, { withSlop = false } = {}) {
            const phraseConfig = {};
            Object.keys(queryObject).forEach((key) => {
                phraseConfig[key] = {
                    query: queryObject[key],
                };

                if (withSlop) {
                    phraseConfig[key].slop = DEFAULT_SLOP;
                }
            });
            return phraseConfig;
        },

        getFilters(queryMeta) {
            const filters = [];
            Object.keys(queryMeta)
                .filter((key) => Object.keys(FILTERABLE_KEYS).includes(key))
                .forEach((key) => {
                    filters.push({
                        term: {
                            [FILTERABLE_KEYS[key]]: queryMeta[key],
                        },
                    });
                });
            return filters;
        },

        buildQueryBody(queryObject, queryMeta) {
            const filters = api.getFilters(queryMeta);

            return {
                size: queryMeta.size || DEFAULT_SIZE,
                query: {
                    bool: {
                        should: [
                            {
                                match: api.getPhraseConfig(queryObject),
                            },
                            {
                                match_phrase: api.getPhraseConfig(queryObject, { withSlop: true }),
                            },
                            {
                                match_phrase_prefix: api.getPhraseConfig(queryObject, { withSlop: true }),
                            },
                        ],
                        filter: filters,
                    },
                },
            };
        },

        async request(queryBody) {
            const response = await fetch(OPENSEARCH_API_URL, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(queryBody),
            });
            return await response.json();
        },

        async phraseSearch(queryObject, queryMeta = {}) {
            const queryBody = api.buildQueryBody(queryObject, queryMeta);
            const response = await api.request(queryBody);
            return {
                hits: response.hits.hits.filter((hit) => hit._score > 0),
            };
        },
    };

    Vue.prototype.$opensearch = {
        phraseSearch: api.phraseSearch,
    };
};
